
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { AppLayout, ErrorPage } from '@app/components';
import { type NextPage } from 'next';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
const NotFoundPage: NextPage = () => {
    const router = useRouter();
    const { t } = useTranslation();
    return (<AppLayout padding='xl' title={t('Not Found')}>
      <ErrorPage buttonText={t('Take me back to home page')} description={t('Unfortunately, this is only a 404 page. You may have mistyped the address, or the page has been moved to another URL.')} onButtonClick={() => router.push('/')} statusCode={404} title={t('Not Found')}/>
    </AppLayout>);
};
// noinspection JSUnusedGlobalSymbols
export default NotFoundPage;

    async function __Next_Translate__getStaticProps__18a3e4c7bbc__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18a3e4c7bbc__ as getStaticProps }
  